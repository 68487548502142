@import 'consts';

body {
  font-size: 16px;
  line-height: 24px;
}

.invalid-message__wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 1px;

  .invalid-message {
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 0.75rem;
    color: $danger;
    text-align: end;
  }
}

mat-select ~ .invalid-message__wrapper .invalid-message {
  top: -5px;
  right: -16px;
}

// buttons

button[mat-raised-button].mat-button-base,
button[mat-flat-button].mat-button-base {
  min-width: 168px;
  height: $button-default-height;
  border-radius: $button-default-height;
}

.c2-brand-theme .mat-mdc-raised-button.mat-primary,
.c2-brand-theme .mat-mdc-flat-button.mat-primary {
  --mdc-protected-button-label-text-color: var(--theme-teal-text);
}

.c2-brand-theme .mat-mdc-raised-button.mat-accent,
.c2-brand-theme .mat-mdc-flat-button.mat-accent {
  --mdc-protected-button-label-text-color: var(--theme-accent-text);
}

// inputs

.native-input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .label {
    display: flex;
    flex-direction: column;

    .label-text {
      margin-bottom: 0.5rem;
    }

    .label-text-new {
      @apply mb-1 text-[18px]/6 text-text-primary-new-color font-['Roboto'] font-medium;
    }
  }

  .input-text {
    height: $input-default-height;
    border-radius: $default-border-radius;
    padding-left: $default-border-radius;
    border: 1px solid rgba(21, 25, 29, 0.1);
    font-size: 0.75rem;

    &:focus {
      @apply outline-[var(--theme-teal-500)] outline-2;
    }

    &:hover:not(:focus) {
      @apply border-black;
    }

    &.ng-dirty.ng-invalid {
      border-color: $danger;

      &:focus {
        outline-color: $danger;
      }

      &:hover:not(:focus) {
        border-color: $danger;
      }
    }
  }

  .custom-select.input-text {
    @apply flex px-3;
  }

  .input-text-new {
    @apply px-3 py-2 text-base text-text-primary-new-color font-['Roboto'] font-normal placeholder:text-text-disabled-color border border-solid border-[#0000003B] rounded;

    &:focus {
      @apply outline-[var(--theme-teal-500)] outline-2;
    }

    &:hover:not(:disabled):not(:focus) {
      @apply border-black;
    }

    &.ng-dirty.ng-invalid {
      border-color: $danger;

      &:focus {
        outline-color: $danger;
      }

      &:hover:not(:focus) {
        border-color: $danger;
      }
    }

    &:disabled {
      @apply opacity-30;
    }
  }

  &.native-draggable-input-wrapper {
    .invalid-message__wrapper {
      width: auto;
      .invalid-message {
        top: 22px;
        width: 320px;
      }
    }
  }
}

.native-input-wrapper .custom-error-wrapper,
.custom-error-wrapper {
  @apply border-red-600;
}

.datetime-picker-input {
  @apply w-28 h-10 px-2 py-2 sm:px-3 sm:w-32 bg-[#F3F3F4] rounded-xl text-sm/[24px] text-[#494656] font-['Manrope'] font-semibold;

  &:focus {
    @apply outline-[var(--theme-teal-500)] outline-2;
  }
}

.yoti-signature-dialog {
  margin: 0 auto !important;
}

.custom-truncate {
  .mdc-list-item__primary-text,
  .mdc-label {
    @apply truncate;
    white-space: nowrap !important;
  }
  .mdc-label {
    max-width: 175px;
  }
}

// markdown editor
.markdown-form-field-wrapper {
  .mat-mdc-text-field-wrapper {
    padding: 0;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: transparent !important;
  }

  thebook-markdown-editor quill-editor {
    .ql-toolbar.ql-snow {
      border-top-left-radius: var(--mdc-outlined-text-field-container-shape);
      border-top-right-radius: var(--mdc-outlined-text-field-container-shape);
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: var(--mdc-outlined-text-field-container-shape);
      border-bottom-right-radius: var(--mdc-outlined-text-field-container-shape);
    }
  }

  thebook-markdown-editor.ng-invalid.ng-dirty quill-editor,
  thebook-markdown-editor.ng-invalid.ng-touched quill-editor {
    border: 1px solid var(--mdc-outlined-text-field-error-outline-color);
    border-radius: var(--mdc-outlined-text-field-container-shape);

    .ql-toolbar.ql-snow {
      border-top: 1px solid var(--mdc-outlined-text-field-outline-color);
      border-left: 1px solid var(--mdc-outlined-text-field-outline-color);
      border-right: 1px solid var(--mdc-outlined-text-field-outline-color);
      border-top-left-radius: var(--mdc-outlined-text-field-container-shape);
      border-top-right-radius: var(--mdc-outlined-text-field-container-shape);
    }

    .ql-container.ql-snow {
      border-bottom: 1px solid var(--mdc-outlined-text-field-outline-color);
      border-left: 1px solid var(--mdc-outlined-text-field-outline-color);
      border-right: 1px solid var(--mdc-outlined-text-field-outline-color);
      border-bottom-left-radius: var(--mdc-outlined-text-field-container-shape);
      border-bottom-right-radius: var(--mdc-outlined-text-field-container-shape);
    }
  }

  mat-error {
    line-height: var(--mat-form-field-subscript-text-line-height);
    font-size: var(--mat-form-field-subscript-text-size);
    letter-spacing: var(--mat-form-field-subscript-text-tracking);
    font-weight: var(--mat-form-field-subscript-text-weight);
  }
}
