@use '@angular/material' as mat;

$c2-teal-palette: (
  50: var(--theme-teal-50),
  100: var(--theme-teal-100),
  200: var(--theme-teal-200),
  300: var(--theme-teal-300),
  400: var(--theme-teal-400),
  500: var(--theme-teal-500),
  600: var(--theme-teal-600),
  700: var(--theme-teal-700),
  800: var(--theme-teal-800),
  900: var(--theme-teal-900),
  A100: var(--theme-teal-a100),
  A200: var(--theme-teal-a200),
  A400: var(--theme-teal-a400),
  A700: var(--theme-teal-a700),
  contrast: (
    50: #00000087,
    100: #00000087,
    200: #00000087,
    300: #00000087,
    400: #00000087,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: #00000087,
    A200: #00000087,
    A400: #00000087,
    A700: #00000087,
  ),
);

:root {
  --theme-teal-50: white;
  --theme-teal-100: white;
  --theme-teal-200: white;
  --theme-teal-300: white;
  --theme-teal-400: white;
  --theme-teal-500: white;
  --theme-teal-600: white;
  --theme-teal-700: white;
  --theme-teal-800: white;
  --theme-teal-900: white;
  --theme-teal-a100: white;
  --theme-teal-a200: white;
  --theme-teal-a400: white;
  --theme-teal-a700: white;
  --theme-teal-text: white;

  --theme-accent-50: white;
  --theme-accent-100: white;
  --theme-accent-200: white;
  --theme-accent-300: white;
  --theme-accent-400: white;
  --theme-accent-500: white;
  --theme-accent-600: white;
  --theme-accent-700: white;
  --theme-accent-800: white;
  --theme-accent-900: white;
  --theme-accent-a100: white;
  --theme-accent-a200: white;
  --theme-accent-a400: white;
  --theme-accent-a700: white;
  --theme-accent-text: white;
}

$c2-purple-palette: (
  50: var(--theme-accent-50),
  100: var(--theme-accent-100),
  200: var(--theme-accent-200),
  300: var(--theme-accent-300),
  400: var(--theme-accent-400),
  500: var(--theme-accent-500),
  600: var(--theme-accent-600),
  700: var(--theme-accent-700),
  800: var(--theme-accent-800),
  900: var(--theme-accent-900),
  A100: var(--theme-accent-a100),
  A200: var(--theme-accent-a200),
  A400: var(--theme-accent-a400),
  A700: var(--theme-accent-a700),
  contrast: (
    50: #00000087,
    100: #00000087,
    200: #00000087,
    300: #00000087,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: #00000087,
    A200: #00000087,
    A400: #00000087,
    A700: #00000087,
  ),
);

$c2-fab-primary-palette: (
  50: white,
  100: white,
  200: white,
  300: white,
  400: white,
  500: white,
  600: white,
  700: white,
  800: white,
  900: white,
  A100: white,
  A200: white,
  A400: white,
  A700: white,
  contrast: (
    50: #e0e0e0,
    100: #b3b3b3,
    200: #808080,
    300: #4d4d4d,
    400: #262626,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #a6a6a6,
    A200: #8c8c8c,
    A400: #737373,
    A700: #666666,
  ),
);

$c2-fab-accent-palette: (
  50: white,
  100: white,
  200: white,
  300: white,
  400: white,
  500: white,
  600: white,
  700: white,
  800: white,
  900: white,
  A100: white,
  A200: white,
  A400: white,
  A700: white,
  contrast: (
    50: #fce4ec,
    100: #f8bcd0,
    200: #f48fb1,
    300: #f06292,
    400: #ec407a,
    500: #e91e63,
    600: #e61a5b,
    700: #e31651,
    800: #df1247,
    900: #d90a35,
    A100: #ffffff,
    A200: #ffd0d8,
    A400: #ff9dae,
    A700: #ff8498,
  ),
);


.c2-brand-theme {
  $c2-primary: mat.define-palette($c2-teal-palette);
  $c2-accent: mat.define-palette($c2-purple-palette);
  $c2-theme: mat.define-light-theme(
    (
      color: (
        primary: $c2-primary,
        accent: $c2-accent,
      ),
    )
  );

  $c2-fab-primary: mat.define-palette($c2-fab-primary-palette);
  $c2-fab-accent: mat.define-palette($c2-fab-accent-palette);
  $c2-fab-theme: mat.define-light-theme(
    (
      color: (
        primary: $c2-fab-primary,
        accent: $c2-fab-accent,
      ),
    )
  );

  @include mat.button-theme($c2-theme);
  @include mat.fab-theme($c2-fab-theme);
  @include mat.tabs-theme($c2-theme);
  @include mat.form-field-theme($c2-theme);
  @include mat.select-theme($c2-theme);
  @include mat.checkbox-theme($c2-theme);
  @include mat.progress-bar-theme($c2-theme);
  @include mat.slider-theme($c2-theme);
  @include mat.datepicker-theme($c2-theme);
  @include mat.stepper-theme($c2-theme);

  .mat-mdc-outlined-button.mat-primary {
    &.mat-mdc-outlined-button:not(:disabled) {
      --mdc-outlined-button-outline-color: var(--theme-teal-500);
    }
  }

  .mat-mdc-outlined-button.mat-accent {
    &.mat-mdc-outlined-button:not(:disabled) {
      --mdc-outlined-button-outline-color: var(--theme-accent-text);
    }
  }

  .mat-mdc-outlined-button.mat-warn {
    &.mat-mdc-outlined-button:not(:disabled) {
      --mdc-outlined-button-outline-color: #D32F2F80;
    }
  }

  .mat-button-toggle-group.custom-toggle.mat-button-toggle-group .mat-button-toggle-checked {
    background-color: var(--theme-teal-900);
  }

  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
    background-color: var(--theme-teal-500);
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: var(--theme-teal-500);
  }

  .mat-mdc-progress-bar.mat-primary {
    --mdc-linear-progress-track-color: var(--theme-teal-400);
  }

  .mat-mdc-progress-bar.mat-accent {
    --mdc-linear-progress-track-color: var(--theme-accent-400);
  }
}

$gray-contrast-palette: (
  50: #e2e2e2,
  100: #b7b7b7,
  200: #878787,
  300: #575757,
  400: #333333,
  500: #0f0f0f,
  600: #0d0d0d,
  700: #0b0b0b,
  800: #080808,
  900: #040404,
  A100: #a6a6a6,
  A200: #8c8c8c,
  A400: #737373,
  A700: #666666,
  contrast: (
    50: #00000087,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

.contrast-buttons-theme {
  $contrast-primary: mat.define-palette($gray-contrast-palette);
  $contrast-accent: mat.define-palette($c2-purple-palette);
  $contrast-buttons-theme: mat.define-light-theme(
    (
      color: (
        primary: $contrast-primary,
        accent: $contrast-accent,
      ),
    )
  );

  @include mat.button-theme($contrast-buttons-theme);

  .mat-mdc-outlined-button.mat-primary {
    &.mat-mdc-outlined-button:not(:disabled) {
      --mdc-outlined-button-outline-color: #0f0f0f80;
    }
  }

  .mat-mdc-outlined-button.mat-accent {
    &.mat-mdc-outlined-button:not(:disabled) {
      --mdc-outlined-button-outline-color: #9654f480;
    }
  }

  .c2-brand-theme & .mat-mdc-raised-button.mat-primary,
  .c2-brand-theme & .mat-mdc-flat-button.mat-primary {
    --mdc-protected-button-label-text-color: white;
  }

  .c2-brand-theme & .mat-mdc-raised-button.mat-accent,
  .c2-brand-theme & .mat-mdc-flat-button.mat-accent {
    --mdc-protected-button-label-text-color: white;
  }
}

$booking-action-button-primary-palette: (
  50: #eee1f3,
  100: #d5b3e1,
  200: #ba80ce,
  300: #9e4dba,
  400: #8927ab,
  500: #74019c,
  600: #6c0194,
  700: #61018a,
  800: #570180,
  900: #44006e,
  A100: #d39dff,
  A200: #bc6aff,
  A400: #a537ff,
  A700: #991eff,
  contrast: (
    50: #00000087,
    100: #00000087,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: #00000087,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$booking-action-button-accent-palette: (
  50: #e0e8f1,
  100: #b3c6db,
  200: #80a1c3,
  300: #4d7bab,
  400: #265e99,
  500: #004287,
  600: #003c7f,
  700: #003374,
  800: #002b6a,
  900: #001d57,
  A100: #89a5ff,
  A200: #567eff,
  A400: #2358ff,
  A700: #0944ff,
  contrast: (
    50: #00000087,
    100: #00000087,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$booking-action-button-warn-palette: (
  50: #fae6e6,
  100: #f2c1c1,
  200: #e99797,
  300: #e06d6d,
  400: #da4e4e,
  500: #d32f2f,
  600: #ce2a2a,
  700: #c82323,
  800: #c21d1d,
  900: #b71212,
  A100: #ffe7e7,
  A200: #ffb4b4,
  A400: #ff8181,
  A700: #ff6868,
  contrast: (
    50: #00000087,
    100: #00000087,
    200: #00000087,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: #00000087,
    A700: #00000087,
  ),
);

.booking-action-buttons {
  $button-primary: mat.define-palette($booking-action-button-primary-palette);
  $button-accent: mat.define-palette($booking-action-button-accent-palette);
  $button-warn: mat.define-palette($booking-action-button-warn-palette);
  $booking-action-buttons-theme: mat.define-light-theme(
    (
      color: (
        primary: $button-primary,
        accent: $button-accent,
      ),
    )
  );

  @include mat.button-theme($booking-action-buttons-theme);

  .mat-mdc-outlined-button.mat-primary {
    &.mat-mdc-outlined-button:not(:disabled) {
      --mdc-outlined-button-outline-color: #F3EAFF;
    }
  }

  .mat-mdc-outlined-button.mat-accent {
    &.mat-mdc-outlined-button:not(:disabled) {
      --mdc-outlined-button-outline-color: #DDF1FF;
    }
  }

  .mat-mdc-outlined-button.mat-warn {
    &.mat-mdc-outlined-button:not(:disabled) {
      --mdc-outlined-button-outline-color: #d32f2f80;
    }
  }

  .c2-brand-theme & .mat-mdc-raised-button.mat-primary,
  .c2-brand-theme & .mat-mdc-flat-button.mat-primary {
    --mdc-protected-button-label-text-color: white;
  }

  .c2-brand-theme & .mat-mdc-raised-button.mat-accent,
  .c2-brand-theme & .mat-mdc-flat-button.mat-accent {
    --mdc-protected-button-label-text-color: white;
  }
}

$booking-conflict-button-primary-palette: (
  50: #fdede0,
  100: #fad3b3,
  200: #f7b680,
  300: #f4984d,
  400: #f18226,
  500: #ef6c00,
  600: #ed6400,
  700: #eb5900,
  800: #e84f00,
  900: #e43d00,
  A100: #ffffff,
  A200: #ffe0d8,
  A400: #ffb7a5,
  A700: #ffa38b,
  contrast: (
    50: #00000087,
    100: #00000087,
    200: #00000087,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: #00000087,
    A200: #00000087,
    A400: #00000087,
    A700: white,
  ),
);

.booking-conflicts {
  $button-primary: mat.define-palette($booking-conflict-button-primary-palette);
  $button-accent: mat.define-palette($booking-action-button-primary-palette);
  $booking-conflicts-button-theme: mat.define-light-theme(
    (
      color: (
        primary: $button-primary,
        accent: $button-accent,
      ),
    )
  );

  @include mat.button-theme($booking-conflicts-button-theme);

  .mat-mdc-outlined-button.mat-primary {
    &.mat-mdc-outlined-button:not(:disabled) {
      --mdc-outlined-button-outline-color: #ef6c0080;
    }
  }

  .mat-mdc-outlined-button.mat-accent {
    &.mat-mdc-outlined-button:not(:disabled) {
      --mdc-outlined-button-outline-color: #74019c80;
    }
  }

  .c2-brand-theme & .mat-mdc-raised-button.mat-primary,
  .c2-brand-theme & .mat-mdc-flat-button.mat-primary {
    --mdc-protected-button-label-text-color: white;
  }

  .c2-brand-theme & .mat-mdc-raised-button.mat-accent,
  .c2-brand-theme & .mat-mdc-flat-button.mat-accent {
    --mdc-protected-button-label-text-color: white;
  }
}

.button-rounded {
  &.mdc-button {
    @apply rounded-full;
  }
}

.theme-background {
  @apply bg-[var(--theme-teal-500)];
}

.theme-background-400 {
  @apply bg-[var(--theme-teal-400)];
}

.theme-background-800 {
  @apply bg-[var(--theme-teal-800)];
}

.theme-text-color {
  @apply text-[var(--theme-teal-500)];
}

.theme-text-accent-color {
  @apply text-[var(--theme-accent-text)];
}

.theme-text-color-400 {
  @apply text-[var(--theme-teal-400)];
}

.theme-text-color-hover {
  @apply hover:text-[var(--theme-teal-400)];
}

.theme-border-color {
  @apply border-[var(--theme-teal-500)];
}

.theme-border-accent-color {
  @apply border-[var(--theme-accent-500)];
}

.theme-border-color-primary {
  @apply border-[var(--theme-teal-text)];
}

.theme-border-color-400 {
  @apply border-[var(--theme-teal-400)];
}

.mat-button-border-dashed {
  &.mdc-button {
    @apply border border-dashed theme-border-color;
  }
}

.expanded-mat-button {
  &.mdc-button {
    @apply h-12;
  }
}

.mat-mdc-card {
  --mdc-elevated-card-container-shape: 8px;
}

.mat-typography p {
  &.no-margin {
    @apply m-0;
  }
}

// css properties for the shell body
body {
  background: #f3f3f3;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, var(--theme-teal-500) 29.69%, var(--theme-teal-900) 100%);
    background-repeat: no-repeat;
    background-size:
      10% 30%,
      10% 30%;
    filter: blur(120px);
    pointer-events: none;
    // z-index: -1;
  }
}

// css properties for the header main nav bar
.header-container {
  background: #dfe1dd;
}

// css properties for the table
table.mat-mdc-table,
thead,
thead tr.mat-mdc-header-row,
thead tr.mat-mdc-header-row th.mat-mdc-header-cell,
tbody,
table tr.mat-mdc-row,
th,
td,
.mat-mdc-paginator {
  border-collapse: collapse;
}

.mat-slide-toggle {
  transform: scale(1.5); /* Change this value to adjust the size */
}

.mat-slide-toggle .mat-slide-toggle-bar {
  width: 3em;
  height: 1em;
}

.mat-slide-toggle .mat-slide-toggle-thumb-container {
  height: 1em;
}

.mat-slide-toggle .mat-slide-toggle-thumb {
  width: 1em;
  height: 1em;
}
