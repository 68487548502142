@use '@angular/material' as mat;
@import "consts";

body.mat-typography {
  font-size: 16px;
  line-height: 24px;
}

mat-button-toggle-group.custom-toggle {
  &.mat-button-toggle-group {
    border-radius: 20px;
    border: 1px solid #00000012;
    background-color: white;
    padding: 5px;
    height: 52px;
    box-shadow: none;
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;

    .mat-button-toggle-appearance-standard {
      background-color: white;
      border-left: none;

      .mat-button-toggle-focus-overlay {
        background-color: var(--theme-teal-900);
      }
    }

    .mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled) {
      &:hover {
        .mat-button-toggle-label-content {
          color: var(--theme-teal-500);
        }
      }
    }

    .mat-button-toggle-label-content {
      padding-left: 24px;
      padding-right: 24px;
    }

    .mat-button-toggle:hover {
      box-shadow: none;
    }

    .mat-button-toggle-checked {
      box-shadow: none;

      background-color: var(--theme-teal-900);
      color: var(--theme-teal-500);
      border: 1px solid var(--theme-teal-500);
      border-radius: 16px;
      height: 44px;

      .mat-button-toggle-label-content {
        bottom: 3px;
      }
    }

    .mat-button-toggle .mat-button-toggle-ripple {
      border-radius: 16px;
    }
  }

  mat-pseudo-checkbox.mat-pseudo-checkbox._mat-animation-noopable {
    display: none;
  }
}

.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background: #fff !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .mdc-snackbar__label {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.mdc-checkbox {
  --mat-option-selected-state-label-text-color: var(--theme-teal-500);
  --mdc-checkbox-selected-focus-icon-color: var(--theme-teal-500);
  --mdc-checkbox-selected-hover-icon-color: var(--theme-teal-500);
  --mdc-checkbox-selected-icon-color: var(--theme-teal-500);
  --mdc-checkbox-selected-pressed-icon-color: var(--theme-teal-500);
  --mdc-checkbox-selected-focus-state-layer-color: var(--theme-teal-500);
  --mdc-checkbox-selected-hover-state-layer-color: var(--theme-teal-500);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--theme-teal-500);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: var(--theme-teal-500);
  --mdc-radio-selected-hover-icon-color: var(--theme-teal-500);
  --mdc-radio-selected-icon-color: var(--theme-teal-500);
  --mdc-radio-selected-pressed-icon-color: var(--theme-teal-500);
  --mat-radio-checked-ripple-color: var(--theme-teal-500);
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--theme-teal-500);
}

mat-slide-toggle.mat-mdc-slide-toggle {
  .mdc-switch--selected {
    .mdc-switch__icons {
      background-color: var(--theme-teal-500);
      border-radius: 10px;
    }
  }

  .mdc-switch .mdc-switch__icon {
    display: none;
  }

  --mdc-switch-selected-track-color: var(--theme-teal-600);
  --mdc-switch-selected-focus-track-color: var(--theme-teal-600);
  --mdc-switch-selected-hover-track-color: var(--theme-teal-600);
  --mdc-switch-selected-pressed-track-color: var(--theme-teal-600);
  --mdc-switch-selected-pressed-state-layer-color: var(--theme-teal-500);
}

.right-side-dialog {
  --mdc-dialog-supporting-text-color: #000000;

  .mat-mdc-dialog-content {
    @apply bg-zinc-100;
    max-height: 100%;
  }

  .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      background-color: transparent !important;
    }

    .mdc-dialog__surface {
      box-shadow: none;
    }
  }
}

.mat-select-override {
  .mat-mdc-text-field-wrapper.mdc-text-field {
    @apply h-11;
  }

  .mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 10px;
  }
}
