$default-vertical-spacing:      15px;
$default-horizontal-spacing:    2%;

$title-vertical-spacing:        50px;
$left-horizontal-spacing:       $default-horizontal-spacing;
$right-horizontal-spacing:      33px;
$input-default-height:          44px;
$input-default-width:           252px;
$button-default-height:         48px;

$default-border-radius:         12px;

$aside-expanded-width:          300px;
$aside-collapsed-width:         100px;

$header-height:                 64px;

$z-index-button:                10;
$z-index-spinner-bg:            11;

$width-desktop-max:             1770px;
$width-desktop-mid:             1440px;
$width-desktop-min:             1250px;
$width-tablet-mid:              1024px;
$width-tablet-min:              876px;
$width-phone:                   768px;
