@import 'consts';

body {
  font-size: 16px;
  line-height: 24px;
}

.invalid-message__wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 1px;

  .invalid-message {
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 0.75rem;
    color: $danger;
    text-align: end;
  }
}

mat-select ~ .invalid-message__wrapper .invalid-message {
  top: -5px;
  right: -16px;
}

// buttons

button[mat-raised-button].mat-button-base,
button[mat-flat-button].mat-button-base {
  min-width: 168px;
  height: $button-default-height;
  border-radius: $button-default-height;
}

.c2-brand-theme .mat-mdc-raised-button.mat-primary,
.c2-brand-theme .mat-mdc-flat-button.mat-primary {
  --mdc-protected-button-label-text-color: var(--theme-teal-text);
}

.c2-brand-theme .mat-mdc-raised-button.mat-accent,
.c2-brand-theme .mat-mdc-flat-button.mat-accent {
  --mdc-protected-button-label-text-color: var(--theme-accent-text);
}

// inputs

.native-input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .label {
    display: flex;
    flex-direction: column;

    .label-text {
      margin-bottom: 0.5rem;
    }

    .label-text-new {
      @apply mb-1 text-[18px]/6 text-text-primary-new-color font-['Roboto'] font-medium;
    }
  }

  .input-text {
    height: $input-default-height;
    border-radius: $default-border-radius;
    padding-left: $default-border-radius;
    border: 1px solid rgba(21, 25, 29, 0.1);
    font-size: 0.75rem;

    &.ng-dirty.ng-invalid {
      border-color: $danger;
    }
  }

  .custom-select.input-text {
    @apply flex px-3;
  }

  .input-text-new {
    @apply px-3 py-2 text-base text-text-primary-new-color font-['Roboto'] font-normal placeholder:text-text-disabled-color border border-solid border-[#0000003B] rounded;

    &.ng-dirty.ng-invalid {
      border-color: $danger;
    }

    &:focus {
      @apply outline-[var(--theme-teal-500)] outline-2;
    }

    &:hover:not(:focus) {
      @apply border-black;
    }
  }

  &.native-draggable-input-wrapper {
    .invalid-message__wrapper {
      width: auto;
      .invalid-message {
        top: 22px;
        width: 320px;
      }
    }
  }
}

.native-input-wrapper .custom-error-wrapper,
.custom-error-wrapper {
  @apply border-red-600;
}

.yoti-signature-dialog {
  margin: 0 auto !important;
}

.custom-truncate {
  .mdc-list-item__primary-text,
  .mdc-label {
    @apply truncate;
    white-space: nowrap !important;
  }
  .mdc-label {
    max-width: 175px;
  }
}
