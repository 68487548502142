@use 'stream-chat-angular/src/assets/styles/scss/variables' with (
  $assetsPath: '../../../node_modules/stream-chat-angular/src/assets/assets'
);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

// import theme from './theme.scss';
@import './theme.scss'; // todo it is block some UI interactive elements

@import 'app/styles/normalize';
@import 'app/styles/consts';
@import 'app/styles/material-override-theme';
@import 'app/styles/global-styles';

/*otp input*/
.ng-otp-input-wrapper {
  @apply outline-none;
}

.otp-input {
  @apply text-text-primary-new-color font-['Roboto'] border border-solid border-[#0000003B];

    &:focus {
      @apply outline-[var(--theme-teal-500)] outline-2;
    }

    &:hover:not(:disabled):not(:focus) {
      @apply border-black;
    }

    &.ng-dirty.ng-invalid {
      border-color: $danger;

      &:focus {
        outline-color: $danger;
      }

      &:hover:not(:focus) {
        border-color: $danger;
      }
    }

    &:disabled {
      @apply opacity-30;
    }
}

/* Carousel */
.dashboard-carousel {
  .owl-carousel {
    @apply pt-10;
  }
  .owl-stage-outer {
    @apply pl-4;
    @screen lg {
      padding-left: calc((100vw - 960px) / 2);
    }
    @screen xl {
      padding-left: calc((100vw - 1184px) / 2);
    }
    @screen 2xl {
      padding-left: calc((100vw - 1312px) / 2);
    }
    @screen 3xl {
      padding-left: calc((100vw - 1408px) / 3);
    }
  }

  .owl-dots {
    @apply absolute top-0 right-0 w-fit leading-3;
  }

  .owl-theme .owl-nav.disabled+.owl-dots {
    @apply mt-0 mx-4 lg:mx-8 xl:mx-12 2xl:mx-16;
  }

  .owl-theme .owl-dots .owl-dot span {
    @apply bg-[#D9D9D9] h-1 w-5 md:w-10 mr-0.5 ml-0 my-0 rounded-none;
  }

  .owl-theme .owl-dots .owl-dot.active span {
    @apply bg-[var(--theme-teal-500)];
  }

  .owl-theme .owl-dots .owl-dot:hover:not(.active) span {
    @apply bg-[var(--theme-teal-600)];
  }
}

/* Chat theming */
@import 'stream-chat-angular/src/assets/styles/scss/index.scss';
$chat-bg-color: #FBFBFB;
.str-chat {
  --str-chat__primary-color: var(--theme-teal-500);
  --str-chat__secondary-background-color: $chat-bg-color;
  --str-chat__channel-list-border-inline-end: none;
  --str-chat__background-color: $chat-bg-color;
  --str-chat__avatar-border-radius: 50%;
  --str-chat__channel-preview-border-block-end: 1px solid #00000014;
  --str-chat__message-actions-box-background-color: #FFFFFF;
  --str-chat__jump-to-latest-message-background-color: #FFFFFF;
  --str-chat__active-primary-color: var(--theme-teal-300);
  --str-chat__surface-color: #f5f5f5;
  --str-chat__secondary-surface-color: #fafafa;
  --str-chat__message-bubble-background-color: var(--theme-teal-900);
  --str-chat__primary-surface-color: #e0f2f1;
  --str-chat__primary-surface-color-low-emphasis: #edf7f7;
  --str-chat__border-radius-circle: 6px;
  --str-chat__own-message-bubble-background-color: var(--theme-teal-900);
  --str-chat__spacing-10: 1rem;
  --str-chat__channel-preview-active-background-color: transparent;
  --str-chat__channel-preview-hover-background-color: #F6F6F6;
  --str-chat__autocomplete-menu-background-color: white;

  &__message &__message-sender-avatar, &__quoted-message-preview &__message-sender-avatar {
    @apply self-center;
  }
  &__message-input &__quoted-message-preview {
    @apply w-full;
  }
}

/* Mobiscroll theming */
@import '@mobiscroll/angular/dist/css/mobiscroll.scss';

.mbsc-material.mbsc-button {
  @apply normal-case;
}

.mbsc-material.mbsc-button-flat.mbsc-hover {
  @apply bg-[#0000000f];
}

.mbsc-material.mbsc-selected .mbsc-calendar-cell-text {
  background: var(--theme-teal-500);
  border-color: var(--theme-teal-500);
}

.mbsc-material.mbsc-range-day::after {
  background: var(--theme-teal-800);
}

.mbsc-material.mbsc-range-control-value.active,
.mbsc-material.mbsc-range-control-label.active,
.mbsc-material.mbsc-popup-button.mbsc-font,
.mbsc-material.mbsc-calendar-week-nr,
.mbsc-material.mbsc-calendar-today {
  color: var(--theme-teal-500);
}

.mbsc-material.mbsc-scroller-wheel-cont::after,
.mbsc-material.mbsc-scroller-wheel-cont::before {
  border-color: var(--theme-teal-500);
}

.mbsc-material.mbsc-range-control-wrapper .mbsc-material.mbsc-segmented-button.mbsc-selected {
  border-bottom-color: var(--theme-teal-500);
}

.mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-week-day.mbsc-ltr,
.mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-day.mbsc-ltr,
.mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-marks.mbsc-ltr {
  text-align: right;
}

/* Bookings Calendar */
.booking-group .mbsc-timeline-resource-col {
  @apply w-20 sm:w-[200px];
}

.booking-group .mbsc-timeline-header-date {
  @apply text-center;
}

.booking-group .mbsc-timeline-header-active {
  @apply w-full;
}

.booking-group .mbsc-timeline-header-active::after {
  @apply inset-0 rounded-none;
  background-color: var(--theme-teal-500);
}

.booking-group .mbsc-timeline-row {
  &.booking-resource {
    @apply h-14;
  }
}

.booking-group .mbsc-timeline-parent {
  padding-left: 0 !important;

  &.team-resource {
    @apply h-[30px];

    .mbsc-timeline-resource-icon {
      @apply mt-4;
    }
  }

  &.talent-resource {
    @apply h-[68px];

    .mbsc-timeline-resource-icon {
      @apply mt-6;
    }
  }
}

.booking-group .mbsc-timeline-resource {
  padding-left: 0 !important;
}

.booking-group .mbsc-material.mbsc-timeline-event-start.mbsc-ltr .mbsc-schedule-event-all-day-background {
  @apply left-0 top-1;
}

.booking-group .mbsc-timeline .mbsc-schedule-event-inner {
  @apply w-full h-6 align-middle pt-3;
}

.booking-group .mbsc-timeline-event-background {
  @apply h-9 w-full border-r border-b border-[#00000080];
}

.mbsc-timeline-event .mbsc-schedule-event-all-day-inner {
  @apply text-center text-[10px]/[14px] font-medium font-['Roboto'];
}

.booking-group .mbsc-material.mbsc-calendar-controls {
  @apply bg-[#F3F3F3];
}

/* Talents Availability Calendar */
.talents-availability-calendar {
  .mbsc-timeline-column-xs {
    width: 37.16px;
  }

  .active-date {
    background-color: var(--theme-teal-500);
    color: white;
  }

  .mbsc-timeline-slots {
    @apply h-0 border-0;
  }

  .mbsc-timeline-event-slot {
    @apply my-0 ml-0 mr-px;
  }

  .mbsc-timeline-header-sticky .mbsc-timeline-header-date {
    @apply hidden;
  }
}

.talents-availability-calendar .mbsc-timeline-resource-col {
  @apply w-20 sm:w-[200px];
}

.talents-availability-calendar .mbsc-timeline-resource-depth-step {
  @apply w-0;
}

.talents-availability-calendar .mbsc-timeline-resource {
  padding-left: 0 !important;
}

.talents-availability-calendar .mbsc-timeline-resource-title {
  @apply h-full;
}

.talents-availability-calendar .mbsc-timeline-parent {
  @apply h-[30px];

  &.team-resource {
    @apply h-[30px];

    .mbsc-timeline-resource-icon {
      @apply mt-[5px];
    }
  }
}

.talents-availability-calendar {
  .mbsc-material.mbsc-calendar-wrapper:after {
    @apply shadow-none;
  }

  .mbsc-timeline-events {
    @apply inset-y-2;
  }

  .mbsc-timeline-day {
    min-width: 37.16px;
  }

  .mbsc-timeline-event.mbsc-schedule-event-all-day {
    @apply p-0;
  }

  .mbsc-timeline-event {
    @apply p-0;
  }
}

.talents-availability-calendar .mbsc-material.mbsc-calendar-controls {
  @apply bg-[#F7F7F7];
}

/* Talent Events Calendar */
.talent-events-calendar .mbsc-material.mbsc-calendar-controls {
  @apply bg-[#F3F3F3];
}

.talent-events-calendar .mbsc-calendar-week-days {
  @apply bg-[#F7F7F7];
}

.event-tooltip .mbsc-material.mbsc-popup-body-anchored {
  @apply rounded-xl;
}

.event-tooltip .mbsc-material.mbsc-popup-arrow {
  @apply rounded-tr-md rounded-bl-md;
}

.event-tooltip .mbsc-popup-arrow-wrapper-top, .event-tooltip .mbsc-popup-arrow-wrapper-bottom {
  @apply h-[30px];
}

/* Mobiscroll theming */

/* This will affect the scrollbar globally */
::-webkit-scrollbar {
  width: 5px; /* Adjust scrollbar width */
}

::-webkit-scrollbar-track {
  background: transparent; /* Change the track (background) color */
}

::-webkit-scrollbar-thumb {
  background: var(--Teal-Hover, rgba(54, 215, 183, 0.4)); /* Change the thumb (scrollbar handle) color */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--Teal-Hover, var(--theme-teal-600)) transparent;
}

/* For Edge */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

// override mdc form field error wrapper padding
.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

// enable contenteditable placeholder
.editable-div[contenteditable='true'] {
  width: 100%;
  outline: none;
  color: #000;
  &:empty::before {
    content: attr(data-placeholder);
    color: #888;
  }
}

// success snackbar styles override
.success-snackbar {
  position: absolute !important;
  display: flex !important;
  justify-content: end !important;
  bottom: 15px !important;
  .mdc-snackbar__surface {
    background: #fff !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .mdc-snackbar__label {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.no-spin-buttons {
  @include hide-input-number-arrows;
}

.chip-border {
  @include chip-border;
}

.feed-type-filter-tabs {
  .mat-mdc-tab-header {
      --mdc-secondary-navigation-tab-container-height: 42px;

      .mat-mdc-tab-labels {
        .mdc-tab {
          @apply min-w-fit px-4;
        }
      }
    }
}
