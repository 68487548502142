@import "web-colors";
@import "dimensions";

@mixin scroll-bar {
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $scroll-bg;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: $scroll-color;
  }
}

@mixin hide-input-number-arrows {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}

@mixin chip-border {
  @apply border-[1px] border-zinc-900 border-opacity-10;
  
  &:focus-within {
      @apply border-[2px] border-[var(--theme-teal-500)];
  }
}
